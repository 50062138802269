export var NotificationTemplateTypeEnum;
(function (NotificationTemplateTypeEnum) {
    NotificationTemplateTypeEnum["REGULAR"] = "REGULAR";
    NotificationTemplateTypeEnum["ECHO"] = "ECHO";
})(NotificationTemplateTypeEnum || (NotificationTemplateTypeEnum = {}));
export class IGroupedBlueprint {
}
export var TriggerTypeEnum;
(function (TriggerTypeEnum) {
    TriggerTypeEnum["EVENT"] = "event";
})(TriggerTypeEnum || (TriggerTypeEnum = {}));
export var TriggerContextTypeEnum;
(function (TriggerContextTypeEnum) {
    TriggerContextTypeEnum["TENANT"] = "tenant";
    TriggerContextTypeEnum["ACTOR"] = "actor";
})(TriggerContextTypeEnum || (TriggerContextTypeEnum = {}));
